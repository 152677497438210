.img-none {
  display: none !important;
}

.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out; 
	-moz-transition: all 0.15s ease-out; 
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out; 
	-moz-transition: all 0.15s ease-out; 
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}

@keyframes fadeinout {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

.circles {
    display: inline-block;
    width: 563px;
    height: 781px;
    position: absolute;
    top: 160px;
    right: 0;
  .circle {
    opacity: 0;
    background-repeat: no-repeat;
    &:first-child {
      width: 220px;
      height: 220px;
      position: absolute;
      top: 0;
      left: 0;
      //animation: fadeinout 7s 1s linear forwards infinite;
      animation: fadeinout 1s 0.5s forwards;
    }
    &:nth-child(2) {
      width: 190px;
      height: 190px;
      position: absolute;
      top: 18px;
      right: 110px;
      //animation: fadeinout 7s 2s linear forwards infinite;
      animation: fadeinout 1s 1s forwards;
    }
    &:nth-child(3) {
      width: 155px;
      height: 155px;
      position: absolute;
      top: 253px;
      left: -14px;
      //animation: fadeinout 7s 3s linear forwards infinite;
      animation: fadeinout 1s 1.5s forwards;
    }
    &:nth-child(4) {
      width: 81px;
      height: 83px;
      position: absolute;
      top: 253px;
      right: 269px;
      //animation: fadeinout 7s 4s linear forwards infinite;
      animation: fadeinout 1s 2s forwards;
    }
    &:nth-child(5) {
      width: 206px;
      height: 206px;
      position: absolute;
      bottom: 148px;
      left: 40px;
      //animation: fadeinout 7s 5s linear forwards infinite;
      animation: fadeinout 1s 2.5s forwards;
    }
    &:nth-child(6) {
      width: 275px;
      height: 275px;
      position: absolute;
      bottom: 181px;
      right: 13px;
      //animation: fadeinout 7s 6s linear forwards infinite;
      animation: fadeinout 1s 3s forwards;
    }
    &:nth-child(7) {
      width: 59px;
      height: 59px;
      position: absolute;
      bottom: 100px;
      right: 270px;
      //animation: fadeinout 7s 7s linear forwards infinite;
      animation: fadeinout 1s 3.5s forwards;
    }
  }
}

a {
  text-decoration: none !important;
}

#header {
    [data-row*=top] {
        margin-top: -10px;
    }
    [data-row*=middle] {
        margin-top: -35px;
        z-index: 9 !important;
    }
}

h1.wp-block-post-title {
  margin-bottom: 50px;
}

#header {
  [data-column=end] {
    [class*=ct-toggle] {
      width: 43px !important;
      height: 43px !important;
      background-color: #FFFFFF !important;
      border-radius: 7px !important;
    }
  }
}

.sidebar-css {
  position: relative;
  [data-block="hook:1064"] {
    position: absolute;
    bottom: -170px;
    right: 0;
  }
}

// 
[data-id="menu"] > ul > li {
  margin-left: 10px;
}
.home [data-id="menu"] > ul > li > a {
	color: #FFFFFF;
}

// li.home-m a {
//   background-color: #033267;
//   color: #FFFFFF !important;
// }
// li.in-beeld-m a {
//   background-color: #009DE0;
//   color: #FFFFFF !important;
// }


nav[data-id="menu"] li[class*=current-menu-] > a {
  background-color: #033267;
  color: #FFFFFF !important;
}

nav[data-id="menu"] li:hover > a {
  background-color: #009DE0;
  color: #FFFFFF !important;
}

.home {
  a.site-logo-container {
    position: relative;
    &:before {
      content: '';
      width: 272.2px;
      height: 100%;
      background: url(/wp-content/uploads/2022/08/Logo-Praktijkonderwijs-Eigenwijs_wit.png);
      display: block;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    img {
      display: none;
    }
  }
}

.c-link {
  position: relative;
  a.wp-block-button__link {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    user-select: none;
  }
}

.style-p {
	line-height: 25px;
}

.container-shadow {
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
}

.style-img {
    img {
	    object-fit: cover;
	    height: 227px !important;
    }
}

.nieuws article {
    .entry-divider {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px !important;
    }
}
// 

.slider-full-images {
  &:after {
      content: '';
      width: 100%;
      height: 415px;
      background: url(/wp-content/uploads/2022/08/Lijn-Path.png);
      position: absolute;
      bottom: 0;
      z-index: 10;
      display: block;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
  }
  .item, .swiper-slide {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &:nth-child(odd) {
      .inner-container {
        .title {
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #FFFFFF;
          }
        }
        .sub-title {
          margin-top: 10px;
        }
      }
    }
    &:nth-child(even) {
      .inner-container {
        position: relative;
        max-width: var(--default-editor, var(--block-max-width));
        width: var(--default-editor, var(--block-width));
        margin-left: var(--default-editor, auto);
        margin-right: var(--default-editor, auto);
        margin: 0 auto;
        align-items: flex-start;
        &:after {
          content: '';
          //display: inline-block;
          display: none !important;
          width: 50%;
          height: 781px;
          background: url(/wp-content/uploads/2022/08/Group-15.png);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: 160px;
          //bottom: -1081px;
          right: 0;
          //animation: scrolling 15s linear infinite;
        }
        .title {
          line-height: 75px;
          max-width: 547px;
          text-align: left;
          margin-bottom: 30px;
        }
        .sub-title {
          position: relative;
          padding: 12px 0 12px 20px;
          max-width: 455px;
          text-align: left;
          width: 100%;
          &:before {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            top: 5px;
            left: 0;
            background-color: #FFFFFF;
          }
        }
        a.button {
          display: none;
        }
      }
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      background-blend-mode: overlay;
    }

    .inner-container {
      z-index: 999;
      height: 1081px;
      padding: 55px 15px 222px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 980px) {
        padding: 80px 0;
      }

      .title {
        color: #ffffff;
        text-align: center;
        text-transform: none;
        margin-bottom: 0;
        z-index: 9;
        //width: 530px;
        @media screen and (max-width: 980px) {
          width: 100%;
        }
      }

      .sub-title {
        color: #ffffff;
        width: 850px;
        text-align: center;
        margin-bottom: 25px;
        z-index: 9;
        @media screen and (max-width: 980px) {
          width: 72%;
        }
      }

      a.button {
        text-align: center;
      }
    }
  }
  .swiper-button-next {
    top: 38%;
    right: 30px;
    @media screen and (max-width: 980px) {
      right: 15px;
    }

    &:after {
      content: '';
      display: block;
      width: 73px;
      height: 44px;
      background: url(/wp-content/uploads/2022/08/awesome-chevron-right-s.png);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .swiper-button-prev {
    top: 38%;
    left: 30px;
    @media screen and (max-width: 980px) {
      left: 15px;
    }

    &:after {
      content: '';
      display: block;
      width: 73px;
      height: 44px;
      background: url(/wp-content/uploads/2022/08/awesome-chevron-left-s.png);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.style-cover {
  position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: url(/wp-content/uploads/2022/08/Path-72.png);
      position: absolute;
      bottom: 0;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
  }
}

[data-prefix="blog"]{
  .entry-excerpt {
    min-height: 80px;
  }
}

.custom-button {
  margin-left: 100px;
}

.home {
  .agenda-home-slider {
    display: block !important;
    ul.wp-container-8 {
      .owl-nav {
        display: block !important;
        float: right;
        position: absolute;
        top: auto !important;
        right: 0;
        bottom: 0 !important;
        button {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
        button.owl-next {
          margin-left: 10px;
        }
        [class*=owl-] {
          font-size: 0;
          width: 45px;
          height: 45px;
          background: #FFFFFF;
          border-radius: 100%;
        }
        .nav-button.owl-prev {
          &:before {
            content: '';
            display: block;
            width: 45px;
            height: 45px;
            background: url(/wp-content/uploads/2022/08/Icon-awesome-chevron-left.png);
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
        .nav-button.owl-next {
          &:before {
            content: '';
            display: block;
            width: 45px;
            height: 45px;
            background: url(/wp-content/uploads/2022/08/Icon-awesome-chevron-right.png);
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
#post-823 {
  .agenda-home-slider {
    display: block !important;
    .gb-container-87efeb7a {
      width: 89px;
    }
    .gb-grid-column-20659d63 {
      padding-left: 10px !important;
    }
    .month {
      border-bottom: 2px solid #707070;
      font-size: 15px !important;
      line-height: 23px;
      time {
        font-size: 15px !important;
        line-height: 23px;
        font-weight: 700;
      }
    }
    .day {
      time {
        font-size: 40px !important;
        line-height: 60px !important;
        font-weight: 700;
      }
    }
    .gb-inside-container {
      h4 {
        font-size: 16px !important;
        margin-bottom: 0 !important;
      }
      .wp-block-post-excerpt {
        p {
          font-size: 14px !important;
          line-height: 21px !important;
        }
      }
    }
    ul {
      .owl-nav {
        display: block !important;
        float: right;
        position: absolute;
        top: -35px;
        right: 0;
        button {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
        button.owl-next {
          margin-left: 10px;
        }
        [class*=owl-] {
          font-size: 0;
          width: 25px;
          height: 25px;
          background: #FFFFFF;
          border-radius: 100%;
        }
        .nav-button.owl-prev {
          &:before {
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url(/wp-content/uploads/2022/08/Icon-awesome-chevron-left.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 9px;
          }
        }
        .nav-button.owl-next {
          &:before {
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url(/wp-content/uploads/2022/08/Icon-awesome-chevron-right.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 9px;
          }
        }
      }
    }
  }
}

.youtube-css {
  width: 100%;
  opacity: 0.5;
}

.nieuws-home-slider {
  display: block !important;
  .entries {
    display: block;
    article {
      margin-bottom: 45px;
    }
    .owl-nav {
      display: block !important;
      float: right;
      button {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
      button.owl-next {
        margin-left: 10px;
      }
      [class*=owl-] {
        font-size: 0;
        width: 45px;
        height: 45px;
        background: #FFFFFF;
        border-radius: 100%;
      }
      .nav-button.owl-prev {
        &:before {
          content: '';
          display: block;
          width: 45px;
          height: 45px;
          background: url(/wp-content/uploads/2022/08/Icon-awesome-chevron-left.png);
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
      .nav-button.owl-next {
        &:before {
          content: '';
          display: block;
          width: 45px;
          height: 45px;
          background: url(/wp-content/uploads/2022/08/Icon-awesome-chevron-right.png);
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.nieuws-slider {
  display: block !important;
  .entries {
    display: block;
    article {
      box-shadow: none !important;
      padding: 0 !important;
      display: flex;
      flex-direction: row;
      .ct-image-container {
        order: 2;
        margin-top: 0 !important;
        height: 105px;
        margin-bottom: 5px !important;
        img {
          object-fit: cover;
        }
      }
      ul.entry-meta {
        order: 1;
        margin-bottom: 5px !important;
        li {
          font-size: 16px;
        }
      }
      .entry-divider {
        display: none;
      }
      .entry-title {
        order: 3;
        font-size: 16px !important;
        margin-bottom: 0 !important;
      }
      .entry-excerpt {
        order: 4;
        font-size: 14px !important;
        line-height: 21px !important;
      }
    }
    .owl-nav {
      position: absolute;
      top: -25px;
      right: 0;
      button {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
      button.owl-next {
        margin-left: 10px;
      }
      [class*=owl-] {
        font-size: 0;
        width: 25px;
        height: 25px;
        background: #FFFFFF;
        border-radius: 100%;
      }
      .nav-button.owl-prev {
        &:before {
          content: '';
          display: block;
          width: 25px;
          height: 25px;
          background: url(/wp-content/uploads/2022/08/Icon-awesome-chevron-left.png);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 9px;
        }
      }
      .nav-button.owl-next {
        &:before {
          content: '';
          display: block;
          width: 25px;
          height: 25px;
          background: url(/wp-content/uploads/2022/08/Icon-awesome-chevron-right.png);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 9px;
        }
      }
    }
  }
}

.month {
  border-bottom: 2px solid #707070;
  time {
    line-height: 45px;
    font-weight: 700;
  }
}

.day {
  time {
    line-height: 120px;
    font-weight: 700;
  }
}

.home {
  li.agenda {
    margin-bottom: 30px;
    .gb-grid-wrapper {
      flex-wrap: inherit;
      align-items: flex-start;
      justify-content: center;
      width: 800px;
      margin: 0 auto;
      .gb-container-87efeb7a {
        border: 2px solid #707070;
        height: auto;
        width: 170px !important;
        .month {
  
        }
        .day {

        }
      }
      .gb-grid-column-20659d63 {
        padding-left: 30px;
      }
    }
  }
}

li.agenda {
  margin-bottom: 30px;
  .gb-grid-wrapper {
    flex-wrap: inherit;
    .gb-container-87efeb7a {
      border: 2px solid #707070;
      height: auto;
      width: 154px;
      .month {

      }
      .day {

      }
    }
    .gb-grid-column-20659d63,
    .gb-grid-column-592c9767 {
      padding-left: 30px;
    }
  }
}

.style-bg {
  figure {
    img {
      margin-left: 80px;
    }
  }
}

[data-block="hook:1064"] {
  padding-top: 0 !important;
}
.terug {
  .wp-block-button {
    a.wp-block-button__link {
      position: relative;
      padding-right: 80px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        right: 0;
        width: 63px;
        height: 63px;
        background: url(/wp-content/uploads/2022/08/Group-14.png);
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}

#background-video {
  position: relative;
  width: 100%;
  height: 481px;
}
#background-video iframe {
  width: 100%;
  height: 100%;
  display: none;
}
#background-video {
  .play-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    font-size: 0px;
    line-height: 0px;
    background: url(/wp-content/uploads/2022/08/Icon-awesome-play-circle.png) center center no-repeat;
  }
}

.ct-contact-info-widget {
  .widget-title {
    margin-left: 35px;
  }
  li {
    align-items: flex-start !important;
    .ct-icon-container {
      margin-top: 5px;
    }
  }
  .contact-info {
    font-size: 18px !important;
  }
}

.sidebar-menu {
  ul {
    display: inline-block;
    li {
      line-height: 30px !important;
      margin-bottom: 0 !important;
      a {
        position: relative;
        padding-left: 20px !important;
        &::before {
          content: '>';
          position: absolute;
          left: 0;
        }
        &:hover {
          font-weight: 700;
        }
      }
    }
    li.current-menu-item {
      a {
        font-weight: 700;
      }
    }
  }
}